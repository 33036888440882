import { required, maxLength } from 'vuelidate/lib/validators';

//export default () => {
export const servicio = (vesselContainerShip) => {
  let LineRepresService = { };
  let AgencyClientTp = { };

  if(vesselContainerShip) {
    LineRepresService = { required };
    AgencyClientTp = { };
  }else{
    AgencyClientTp = { required };
    LineRepresService = { };
  }

  return {
    ServiceCode: { required, maxLength: maxLength(250) },
    StartDate: { required },
    EndDate: { required },
    ServiceDs: { required, maxLength: maxLength(250) },
    Status: { required },
    TpVesselId: { required },
    LineRepresServiceId: LineRepresService,
    AgencyClientTpId: AgencyClientTp,
    ServiceTonnage: {},
    ServiceTeus: {},
  };
};
